import React, {useEffect, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {DataFrame} from 'dataframe-js';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function round(number) {
    return Math.round(number * 10) / 10
}

function CSVDataGrid() {
    const [df, setDF] = useState(null);
    const [columnDefs, setColumnDefs] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [filter, setFilter] = useState("");
    const [tt, setTT] = useState(false)
    const [level, setLevel] = useState(45)
    const [hilly, setHilly] = useState(0.5)
    const [unlocked, setUnlocked] = useState(true)

    useEffect(() => {
        DataFrame.fromCSV('equipment.csv').then(setDF);
    }, []);

    useEffect(() => {
        if (df) {
            console.log("update", filter, tt, level)
            const f = filter.toLowerCase()
            let d = df
            d = d.filter(row => !(row.get('frame_level') > parseInt(level)) && !(row.get('wheels_level') > parseInt(level)));
            if (filter && filter !== '') {
                d = d.filter(row => row.get('frame').toLowerCase().includes(f) || row.get('wheels').toLowerCase().includes(f));
            }
            if (!tt) {
                d = d.filter(row => row.get('tt') == 'False')
            }
            if (!unlocked) {
                d = d.filter(row => row.get('frame_level') > 0 && row.get('wheels_level') > 0)
            }
            d = d.withColumn('time', row => round((1 - hilly) * parseFloat(row.get('flat')) + hilly * parseFloat(row.get('climb'))))
            d = d.sortBy('time') //.slice(0, 100)
            setColumnDefs(d.listColumns().filter(h => tt || (h !== 'tt')).map(header => ({field: header})));
            setRowData(d.toCollection());
        }
    }, [filter, tt, level, hilly, unlocked, df]);

    return (
        <div className="ag-theme-alpine" style={{height: 800, width: '100%'}}>
            <div id="filters">
                <span>
                    <label htmlFor="filter">Filter frame/wheels</label>
                    <input
                        id='filter'
                        type="text"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        placeholder="Filter value"
                    />
                </span>
                <span>
                    <label htmlFor='tt'>Include TT</label>
                    <input
                        id="tt"
                        type="checkbox"
                        checked={tt} // Use checked instead of value for checkbox
                        onChange={(e) => setTT(e.target.checked)} // Use e.target.checked
                        placeholder="TT"
                    />
                </span>
                <span>
                    <label htmlFor='unlocked'>Include unlocked</label>
                    <input
                        id="unlocked"
                        type="checkbox"
                        checked={unlocked} // Use checked instead of value for checkbox
                        onChange={(e) => setUnlocked(e.target.checked)} // Use e.target.checked
                        placeholder="TT"
                    />
                </span>
            </div>
            <div className="slider">
                <span>Level</span>
                <input
                    type="range"
                    min="4"
                    max="45"
                    step="1"
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                />
                <span>{level}</span>
            </div>
            <div className="slider">
                <span>Flat {Math.round((1.0 - parseFloat(hilly)) * 100)}%</span>
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.05"
                    value={hilly}
                    onChange={(e) => setHilly(e.target.value)}
                />
                <span>Hilly {Math.round((parseFloat(hilly)) * 100)}%</span>
            </div>
            <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
            />
        </div>
    );
}

export default CSVDataGrid;
